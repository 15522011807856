.Page404 {
  padding: 100px 0;

  &-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
      font-size: 18px;
      margin-top: 5px;
    }
  }

  &-alert {
    width: 70px;
    height: 70px;
  }

  button {
    margin-top: 30px;
  }
}
