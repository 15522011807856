@import "src/styles/lib";

.ShopByCategories {
  width: 100%;
  &__wrapper {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(4, 4fr);
    width: 100%;
    @include tablet {
      grid-template-columns: repeat(1, 1fr);
    }
    &-item {
      display: flex;
      flex-direction: column;
      border-radius: 3px;
      border: 1px solid #ddd;
      box-shadow: 0 4px 10px -4px #aaa;
      padding: 10px 20px;
      width: 100%;
      max-width: 355px;
      transition: all 0.1s;
      background-color: white;
      &:hover {
        text-decoration: underline;
        transition: all 0.1s;
        cursor: pointer;
      }
      label {
        margin-bottom: 0;
        font-size: 20px;
        font-weight: 400;
        &:hover {
          cursor: pointer;
          color: #012db6;
          text-decoration: underline;
          transition: all 0.1s;
        }
      }

      &--img {
        display: flex;
        width: 100%;
        height: 100%;
        margin-right: 0.75rem;
        margin-bottom: 20px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
