@import "src/styles/lib";

.SingleProduct__content__buttons {
  @include tablet {
    > div {
      flex-direction: column;
    }
    &--lookMore {
      margin-top: 36px;
    }
  }

  .Quantity {
    margin: 0;
  }
  &--buttons {
    display: flex;
    // flex-direction: column;
    align-items: center;
  }
  &--lookMore {
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
    span {
      font-size: 15px;
      font-weight: 400;
      line-height: 20.25px;
      color: #90969f;
      text-decoration: underline;
    }
  }
  .btn--addtocart {
    margin-left: 0 !important;
    max-width: 187px;
    width: 100%;
    white-space: nowrap !important;
    margin-right: 0 !important;
  }

  .btn--wishlist {
    background-color: white !important;
    color: black !important;
    border-color: black !important;
    border: 1px solid black;
    margin-left: 16px !important;
    max-width: 187px !important;
    width: 100% !important;
  }
}
