@import "src/styles/lib";

.Home {
  .container {
    @include mobile {
      padding: 0;
    }
  }
  &__border {
    width: 100%;
    height: 1px;
    background-color: #e8e8e8;
    margin-top: 100px;

    @include tablet {
      margin-top: 48px;
    }
  }
}
