@import "./../../styles/_lib.scss";

.HowToSingle {
  margin-top: 50px;
  margin-bottom: 50px;

  &__wrapper {
    &_video {
      margin-bottom: 30px;
      img {
        width: 100%;
        height: 455px;
      }
    }

    &__content {
      h1 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
        margin-bottom: 20px;
      }
      &_card {
        padding: 18px 24px;
        border-radius: 12px;
        background-color: white;
        h3 {
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;
          margin-bottom: 20px;
        }
        p {
          font-size: 15px;
          line-height: 25px;
          font-weight: 400;
          margin-bottom: 17px;
        }
        ul {
          li {
            list-style: disc;
            margin-left: 19px;
          }
        }
      }
    }
  }
}
