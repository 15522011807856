@import "./src/styles/lib";

.Login {
  display: flex;
  flex: 1;

  @include tablet {
    flex-direction: column;
  }

  .customInputWrapper {
    display: flex;
    align-items: center;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    height: 44px;
    z-index: 9999;
    padding-right: 15px;
    &--error {
      border-color: #ef3636 !important;
    }
  }

  .customInput {
    border: none !important;
    height: 39px !important;
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 50px;
    right: 0;
    left: 0;

    span,
    strong {
      font-size: 16px;
      line-height: 21.6px;
      font-weight: 500;
    }

    strong {
      font-weight: 700;
      margin-left: 5px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  &-moto {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    @include mobile {
      justify-content: center;
      align-items: center;
    }
    img {
      width: 150px;
      margin-bottom: 10px;
    }
    h2 {
      font-family: "Bebas Neue", cursive;
      font-size: 45px;
      color: #4a5568;
    }
  }
  &__submit {
    background-color: #1a3f9c !important;
    margin-top: 38px !important;
  }
  &__wrapper {
    width: 100%;
    max-width: 422px;
    margin: 0 auto;
    border: 1px solid #e4e4e4;
    background: white;
    padding: 25px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    &-m {
      border: 0 !important;
      background-color: transparent !important;
    }

    &__keeper {
      margin-top: -14px !important;
    }

    &__img {
      display: flex;
      justify-content: center;
      margin-top: 34px;
    }

    &-text {
      display: inline-block;
      margin: 0;
      color: #7c7c7c !important;
      font-weight: 500;
      font-size: 17px !important;
      line-height: 23px;
    }

    > button {
      margin-top: 30px;
      width: 100%;
    }
    &_title {
      margin-bottom: 30px;
    }
    h4 {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
    }
  }

  &__links {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .form-control {
    padding: 0 15px !important;
    border-radius: 0 !important;
  }
  &__border {
    border-bottom: 1px solid #dadde1;
    width: 100%;
    margin-top: 30px;
  }
  &__register {
    width: auto !important;
  }
}
