.ShopByCategory {
  &__banner {
    display: flex;
    width: 100%;
    height: 420px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &-loading {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 70px;
    }
  }
  &__top {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 40px;
    margin-top: 30px;
    &__subcategories {
      display: flex;
      justify-content: space-between;
      margin-top: 7px;
      align-items: baseline;
    }
    span {
      font-size: 24px;
      line-height: 32.4px;
      font-weight: 700;
      text-transform: capitalize;
    }
    &_btn {
      border: 1px solid #d6d6d6 !important;
      background-color: transparent !important;
      border-radius: 0 !important;
    }
  }
}
