@import "./src/styles/lib";

.Footer {
  width: 100%;
  background-color: #fff;
  margin-top: 105px;
  // position: absolute;
  // bottom: 0;

  @include tablet {
    margin-top: 0;
  }
  .modal-content {
    border-radius: 12px !important;
  }
  &__desktop {
    display: flex;
    background-color: #fff;
    padding: 60px 30px 60px 30px;
    justify-content: center;
    align-items: center;

    &_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      @include mobile {
        justify-content: center;
      }
    }
    &_element {
      display: flex;
      align-items: center;
      @include mobile {
        margin-bottom: 50px;
        flex-direction: column;
      }
      &-icon {
        display: flex;
        border: 2px solid $primary;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        align-items: center;
        justify-content: center;
        margin-right: 20px;

        @include mobile {
          margin-bottom: 10px;
        }

        svg {
          fill: $primary;
          width: 35px;
          height: 28px;
        }
      }
      &-text {
        @include mobile {
          display: flex;
          align-items: center;
          flex-direction: column;
        }
        h1 {
          font-size: 20px;
          font-weight: 700;
          color: $primary;
          span {
            color: white;
          }
          text-transform: uppercase;
        }
        > span {
          font-size: 14px;
          font-weight: 300;
          color: white;
        }
      }
    }
  }

  &__list {
    &-head {
      text-transform: uppercase;
      font-weight: 600;
      color: black;
      margin-bottom: 35px !important;
    }
    li {
      margin-bottom: 20px;
    }
    span {
      color: #626262 !important;
      transition: all 0.2s;
      &:hover {
        color: $primary !important;
        cursor: pointer;
      }
    }

    a {
      color: #626262 !important;
      &:hover {
        color: $primary !important;
      }
    }

    &-phone,
    &-location {
      display: flex;
      align-items: center;
      svg {
        margin-right: 20px;
      }
    }
    &-phone {
      a {
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        color: $primary !important;
      }
    }
  }

  &__items {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(2, 2fr);
    align-items: flex-start;
    padding-top: 4.235801032em;

    @include mobile {
      flex-direction: column;
    }
    &__right {
      width: 65%;
      @include mobile {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
      }
    }
    &--text {
      width: 35%;
      @include mobile {
        width: 100%;
      }
    }
  }

  &__copyright {
    color: #888888;
    background-color: #fff;
    padding: 33px 0;

    span {
      display: block;
      text-align: center;
      font-size: 14px;
      line-height: 35px;

      @include mobile {
        font-size: 12px;
      }
    }
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 20px;

    @include tablet {
      flex-direction: column;
    }
  }

  &__icons {
    display: flex;
    align-items: center;
    &_icon {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__tabs {
    @include tablet {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin: 23px 0;
      margin-bottom: 0;
    }
    a {
      font-size: 16px;
      font-weight: 500;
      line-height: 21.6px;
      letter-spacing: -2%;
      color: black !important;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__logo_and_icons__wrapper {
    @include tablet {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
