@import "src/styles/lib";

.Layout {
  width: 100%;
  margin-top: 50px;
  position: relative;
  margin-bottom: 67px;

  @include mobile {
    margin-top: 30px;
  }

  &__head {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    border: 1px solid #eeeeee;
    h1 {
      margin-bottom: 0;
    }

    @include mobile {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
    }
  }

  &--no-products {
    @include desktop {
      .ShopFilterActions {
        margin-bottom: 0;
      }
    }
  }
}
