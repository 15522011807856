@import "./../../styles/_lib.scss";

.Wishlist {
  &__wrapper {
    margin: 55px 0;
  }

  .WishlistButton {
    @include tablet {
      width: 40px !important;
      height: 40px !important;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  .btn--addtocart {
    text-align: center;
    height: 47px;
    color: #012db6;
    margin: 10px 0;
    display: block;
    // flex: 1 1 auto;
    font-size: 15px;
    white-space: nowrap;
    padding: 10px 22px;
    // max-width: 200px;
    // min-width: 200px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -2%;
    transition: 300ms ease;
    border-radius: 100px;
    border: 1px solid #012db6;
    background-color: transparent;

    &:hover {
      background-color: darken($primary, 4%);
    }
    @include tablet {
      padding: 0 10px;
      max-width: none;
      // margin-right: 15px;
      min-width: unset;
      width: fit-content;
    }
    @include mobile {
      padding: 0 10px;
      max-width: none;
      // margin-right: 15px;
      min-width: unset;
      width: fit-content;
    }

    &:disabled {
      background-color: #d8d8d8;
      border-color: #d9d9d9;
      color: white;
    }
  }
}
