/**
 * IMPORTANT
 *
 * This file is imported automatically before every .scss file in the project by
 * sass-resources-loader in the webpack config (both in dev and production). This
 * file must not output any CSS -- use it only for shared variables, configuration
 * and mixins.
 *
 *
**/

@import "~bootstrap/scss/functions";
@import "bootstrap-variables";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$header-height: 66px;
$mobile-header-height: 70px;
$mq-small: 767px;
$mq-medium: 992px;
$d-small: 1024px;
$d-medium: 1240px;

/**
 * For hiding text while allowing screen readers to read it.
 * Copied from the sr-only class in Twitter Bootstrap.
 */
@mixin screenReaderOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/**
 * Space function corresponds to the Bootstrap utility functions, i.e.
 * class="ml-2" is equal to margin-left: space(2);
 */
@function space($idx) {
  @return nth(nth($spacers, $idx + 1), 2);
}

/**
Mixin for including styles for mobile
*/
@mixin mobile {
  @media (max-width: $mq-small) {
    @content;
  }
}

/**
Mixin for including styles for tablet
*/
@mixin tablet {
  @media (max-width: $mq-medium) {
    @content;
  }
}

/**
Mixin for including styles for tablet
*/
@mixin small-desktop {
  @media (max-width: $d-medium) {
    @content;
  }
}

/**
Mixin for including styles for desktop
*/
@mixin desktop {
  @media (min-width: ($mq-small + 1)) {
    @content;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2020-7-14 10:26:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin backgroundAutofill($color: #ffffff) {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus &:-webkit-autofill,
  &:-webkit-autofill:hover &:-webkit-autofill:focus,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px $color inset !important;
  }
}

//convert pixel to em
@function em($pixels, $context: 16) {
  @return #{$pixels/$context}em;
}

@mixin customScrollbar($width: 6px, $color: $black) {
  //&::-webkit-scrollbar-track {
  //}

  &::-webkit-scrollbar {
    width: $width;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $color;
  }
}
