.ProfileHeader {
  position: relative;
  .modal-dialog {
    max-width: 700px !important;
  }
  &:hover {
    cursor: pointer;
  }
  &__dropdown {
    position: absolute;
    right: 40px;
    top: 40px;
    background: white;
    border-radius: 6px;
    padding: 20px;
    z-index: 101;
    width: 300px;
    // height: 320px;
    h1 {
      font-size: 20px;
      line-height: 27px;
      font-weight: 700;
      color: #000000;
      margin-bottom: 10px;
    }
    &_items {
      display: flex;
      flex-direction: column;
      // height: 87%;
      // overflow: scroll;
      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }

      /* Hide scrollbar for IE, Edge and Firefox */
    }
    &_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e8e8e8;
      padding: 10px 0;

      &:hover {
        cursor: pointer;
      }
      &:first-child {
        border-top: 1px solid #e8e8e8;
      }
      &:last-child {
        border: none;
        padding-bottom: 0;
      }

      &_desc {
        display: flex;
        align-items: center;
        .differentcolor {
          color: #595959 !important;
        }
        span {
          font-size: 16px;
          font-weight: 500;
          line-height: 21.6px;
        }
      }
    }
  }
}

.Profile {
  display: flex;
  &__left {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e8e8e8;
    justify-content: space-between;
    max-width: 183px;
    width: 100%;
    margin: -30px 0 !important;
    padding: 30px 0;
    &_items {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &_item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      border-right: 2px solid transparent;
      &_active {
        span {
          color: #012db6 !important;
        }
        border-right: 2px solid #012db6 !important;
      }
      &:hover {
        cursor: pointer;
      }
      span {
        font-size: 15px;
        color: #000;
        font-weight: 700;
        line-height: 20.25px;
      }
    }
  }
  &__right {
    margin-left: 73px;
    h1 {
      font-size: 22px;
      line-height: 30px;
      font-weight: 700;
      color: #000;
      margin-bottom: 30px;
    }
  }
}

.Overlay {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}
