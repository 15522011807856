@import "src/styles/lib";

.ProductSingleCarousel {
  position: relative;
  min-height: 100%;

  // &--out_of_stock {
  //   // .ProductSingleCarousel {
  //   //   &__thumbs {
  //   //     bottom: 90px;
  //   //   }
  //   // }
  // }
  &__image {
    width: 706px;
    overflow: hidden;
    position: relative;
    min-height: 100%;
    max-height: 486px;
    border-radius: 12px;
    padding: 5px;
    background-color: white;
    // border: 1px solid #eeeeee;
    &_wrapper {
      display: flex;
      flex-direction: column;
      @include mobile {
        padding-left: 0;
      }
    }
    // .slick-list {
    //   height: 468px;
    // }
    @include tablet {
      width: 100%;
      max-height: 380px;
    }
  }

  &__item {
    &:hover {
      cursor: zoom-in;
    }
    img {
      object-fit: contain;
      max-height: 515px;
      border-radius: 12px;
      @include mobile {
        height: 480px;
        max-height: 480px;
        object-fit: contain;
      }

      @include tablet {
        height: 480px;
        max-height: 480px;
        object-fit: contain;
      }
    }
  }

  &__no-stock {
    top: 0;
    right: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: flex-end;
    span {
      display: flex;
      align-items: center;
      width: 100px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      padding: 5px;
      border-radius: 12px;
      text-transform: lowercase;
      background-color: $primary;
      position: absolute;
      top: 40px;
      right: 0;
      transform: rotate(-45deg);
    }
  }

  @include tablet {
    width: auto;
    max-height: 480px;
    margin-left: -15px;
    margin-right: -15px;
  }

  @include mobile {
    width: auto;
    max-height: 480px;
    margin-left: -15px;
    margin-right: -15px;
  }

  .slick-slide,
  .slick-track {
    :focus {
      outline: none !important;
    }

    outline: none !important;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  &__thumbs {
    display: flex;
    // z-index: 3;
    // left: 25px;
    // top: 0;
    // position: absolute !important;
    // transition: all 0.3s ease-in-out;
    // width: 71px;
    .slick-list {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 25px;
    }

    .slick-slide {
      width: 91px !important;
      margin-right: 13px;
    }

    @include tablet {
      // left: 20px;
      padding-left: 20px;
      bottom: 0;
      top: unset;
    }

    &__item {
      border: 1px #fff solid;
      width: auto !important;
      width: 91px !important;
      height: 79px !important;
      background-color: white;

      border-radius: 12px;
      cursor: pointer;
      margin-bottom: 7px !important;

      img {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        object-fit: contain;
      }
    }

    .slick-current {
      .ProductSingleCarousel__thumbs__item {
        border: 1px solid $primary;
      }
    }
  }
}

.ProductSingleCarouselLoader {
  @include tablet {
    width: 100%;
    height: auto;
    svg {
      width: 100%;
      height: auto;
      display: Block;
    }
  }
  @include mobile {
    width: auto;
    margin-left: -15px;
    margin-right: -15px;
  }
}
