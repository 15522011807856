@import "../../../styles/lib";

.CustomArrow {
  display: flex !important;
}

.Slide {
  // max-width: 1650px;
  margin: 0 auto;
  position: relative;
  width: 100%;
  min-height: 444px;

  &__progressbar {
    display: flex;
    margin-top: -5px;
    &__wrapper {
      height: 3px;
      width: 100%;
      transition: width 0.1s ease-in-out;
    }

    .bar {
      transition: width 0.1s ease-in-out;
      background: linear-gradient(to left, #012db6, transparent 90%);
      height: 100%;
    }

    .bar[style*="width: 0%"] {
      transition: none;
    }
  }

  // @include tablet {
  //   min-height: 400px;
  //   img {
  //     min-height: 400px;
  //     max-height: 400px;
  //   }
  // }

  // @include mobile {
  //   min-height: 300px;
  //   img {
  //     min-height: 300px;
  //     max-height: 300px;
  //   }
  // }

  &__item {
    width: 100%;
    height: 444px;
    position: relative;
    @include tablet {
      height: 300px;
    }
    @include mobile {
      height: 200px;
    }
  }

  &__content {
    position: absolute;
    width: 100%;
    height: 444px;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;

    @include mobile {
      height: 200px;
    }

    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.1);
      transform: all 0.3ms;
    }

    h2 {
      font-size: 28px;
      font-weight: 700;
      line-height: 1.35em;
      margin-bottom: 20px;
    }
    button {
      margin: 0 auto;
    }

    @media (max-width: 400px) {
      padding: 15px;
      h2 {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include mobile {
      height: 200px;
    }
  }

  .slick-slide,
  .slick-track {
    :focus {
      outline: none !important;
    }
    outline: none !important;
  }

  .slick-arrow {
    top: 50% !important;
    transform: translateY(-50%) !important ;
    z-index: 1;
    width: 30px;
    height: 25px;
    &:before {
      content: "";
      display: none;
    }
    &:hover {
      fill: $primary;
    }
  }

  .slick-prev {
    left: 50px;
    @include mobile {
      left: 5px;
    }
  }
  .slick-next {
    right: 50px;

    @include mobile {
      right: 5px;
    }
  }

  @include tablet {
    margin-left: -30px;
    margin-right: -30px;
    width: auto;
    min-height: 322px;
    a {
      img {
        object-fit: cover;
      }
    }
  }

  @include mobile {
    margin-left: -15px;
    margin-right: -15px;
    width: auto;
    min-height: 222px;
    a {
      img {
        object-fit: cover;
      }
    }
  }

  &__btn-down {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
  }
}
