@import "src/styles/lib";

.ShopBySubSubCategories {
  &__desc {
    p {
      line-height: 1.5;
      color: #666;
      margin-bottom: 20px;
    }
  }
  &__wrapper {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(4, 4fr);
    width: 100%;
    margin-top: 45px;

    @include tablet {
      grid-template-columns: repeat(1, 1fr);
    }

    &-item {
      display: flex;
      align-items: center;
      border-radius: 3px;
      border: 1px solid #ddd;
      box-shadow: 0 4px 10px -4px #aaa;
      padding: 10px 20px;
      width: 100%;
      max-width: 215px;
      transition: all 0.1s;
      &:hover {
        text-decoration: underline;
        transition: all 0.1s;
        cursor: pointer;
      }
      label {
        margin-bottom: 0;
        &:hover {
          cursor: pointer;
          color: #012db6;
          text-decoration: underline;
          transition: all 0.1s;
        }
      }

      &--img {
        display: flex;
        width: 34px;
        height: 34px;
        margin-right: 0.75rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
