@import "src/styles/lib";
.ShopMobileFilters {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: #f8f8f8;
  z-index: 12;
  padding: 15px;
  display: flex;
  flex-direction: column;
  transform: translateX(-100%);
  transition: 0.3s;

  &--opened {
    transform: translateX(0);
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 30px;

    &_back {
      background-color: transparent;
      border: none;
    }

    &_title {
      flex: 1;
      text-align: center;
      font-size: 22px;
      line-height: em(35px, 22px);
      text-transform: capitalize;
    }

    &_clear {
      cursor: pointer;
      font-size: 12px;
      font-weight: 500;
      line-height: em(40px, 12px);

      &:hover {
        opacity: 0.9;
      }
    }
  }

  &__main {
    height: calc(100% - 165px);
    overflow-y: auto;
    @include customScrollbar(3px);

    &_list {
      width: 100%;
      padding-right: 5px;
    }

    &_list_item {
      font-size: 14px;
      font-weight: 700;
      line-height: em(18px, 14px);

      text-transform: uppercase;
      color: #000000;
      cursor: pointer;

      display: flex;
      justify-content: space-between;
      padding: 20px 0 20px 10px;
      border-bottom: 1px solid $border-color;

      &:first-child {
        border-top: 1px solid $border-color;
      }
    }
  }

  &__bottom {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;

    background-color: #f8f8f8;
    padding: 30px 15px;
  }

  .ShopMobileFiltersScreen {
    width: 100%;
    height: 100%;
    transition: 0.3s;
  }
}
.fade-enter .btn {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-enter-active .btn {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit .btn {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit-active .btn {
  opacity: 0;
  transform: translateX(100%);
}
.fade-enter-active .btn,
.fade-exit-active .btn {
  transition: opacity 500ms, transform 500ms;
}
