@import './src/styles/lib';

.OrderSingleApproved {
  &__wrapper {
    margin-bottom: 100px;
    @include mobile {
      margin-bottom: 150px;
    }
    .CartSidebar-footer--left {
      @include tablet {
        margin-right: 0;
      }
    }
    .tableHead{
      width: 31%;
      display: flex;
      justify-content: space-around;
      .OrderSingleApproved__quantity_title_price{
        min-width: 88px;
        text-align: right;
      }
      .OrderSingleApproved__quantity_title_value{
        min-width: 75px;
        text-align: left;
      }
      .OrderSingleApproved__quantity_title{
        margin-right: 0;
      }

    }
    .CartItem__attributes__quantity{
      min-width: 100px;
      display: flex;
      justify-content: space-around;
    }
    .CartTotal {
      justify-content: space-between !important;
      padding: 20px;
      width: 100%;
      position: fixed;
      left: 0;
      bottom: 0;
      .CartSidebar-footer {
        padding: 0 !important;
      }

      &__wrapper__approved {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include tablet {
          flex-direction: column;
          &--time {
            width: 100%;
            justify-content: center;
            padding-top: 22px;
            margin-top: 22px;
            border-top: 1px solid $border-color;
          }
        }
      }
    }
  }
  &__quantity {
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 24.3px !important;
    color: #000000 !important;
    &_title {
      font-size: 18px;
      font-weight: 400;
      line-height: 24.3px;
      color: #000;
      margin-right: 10px;
    

      &_pr {
        // position: absolute;
        bottom: 117px;
        margin-left: -10px;
      }
    }
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 17px;
  }
  .ProfileOrdersSingle__order-nr {
    align-self: unset !important;
    margin-bottom: 0 !important;
    width: 65% !important;
  }

  .CartItem__wrapper {
    margin-right: 0 !important;
    @include tablet {
      flex-direction: column !important;
      align-items: flex-start;
      .CartItem__content__title {
        font-size: 18px;
        line-height: 25px;
        font-weight: 700;
       
      }
      .HowTo__content_big_card_desc_label {
        margin-right: 0 !important;
        &_minutes {
          span {
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
          }
        }
      }
      &__right {
        flex-direction: column;
        width: 100%;

        &--m {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 25px;
          &-span {
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
          }
          .CartItem__attributes__quantity {
            font-size: 20px;
            line-height: 27px;
            color: #000;
            font-weight: 700;
          }
        }
      }
    }
    &__left {
      @include tablet {
        margin-right: 0;
        padding-right: 0;
        border-right: 0;
      }
    }
  }
  &__upper_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
