@import "./../../../styles/_lib.scss";

.BrandsIcons {
  margin-top: 105px;
  padding: 48px 0;
  background: white;
  @include tablet {
    margin: 55px -15px;
    padding: 48px 15px;
  }
  &__wrapper {
    display: flex;

    @include tablet {
      flex-direction: column;
      align-items: flex-start;
      &_title {
        margin-right: 0 !important;
        margin-bottom: 25px;
      }
    }

    &_title {
      margin-right: 100px;
      span {
        font-size: 24px;
        line-height: 32.4px;
        font-weight: 700;
      }
    }
  }

  &__list {
    flex: 1;
    display: grid;
    grid-gap: 70px;
    grid-template-columns: repeat(6, 1fr);
    user-select: none;

    @include tablet {
      grid-gap: 70px;
      grid-template-columns: repeat(4, 1fr);
    }
    @include mobile {
      grid-gap: 30px;
      grid-template-columns: repeat(3, 1fr);
    }

    &__item {
      width: 100px;
      height: 60px;
      position: relative;
      margin-right: 60px;
      list-style-type: none;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        &:hover {
          img {
            filter: none;
            opacity: 1;
          }
        }
        h4 {
          font-size: 16px;
          text-align: center;
        }
        img {
          filter: grayscale(100%);
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          transition: all 0.3s ease-in-out;
          opacity: 0.2;

          @include mobile {
            position: initial;
            filter: none;
            opacity: unset;
          }
        }
      }

      &__name {
        font-weight: 600;
        font-size: 16px;
        margin: 0;
        text-transform: uppercase;
      }
    }
  }
}
