@import "./../../styles/_lib.scss";

.Brands {
  width: 100%;
  margin-top: 129px;
  position: relative;
  margin-bottom: 50px;

  @include tablet {
    margin-top: 35px;
    margin-bottom: 50px;
  }

  @include mobile {
    margin-top: 35px;
    margin-bottom: 50px;
  }

  &--mt {
    margin-top: 50px;
  }
}
