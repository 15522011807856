@import "./../../styles/_lib.scss";

.CartItem {
  width: 100%;
  min-width: 0;
  min-height: 0;
  display: flex;
  position: relative;
  margin-bottom: 16px;
  align-items: center;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-radius: 12px;
    width: 100%;
    padding: 20px;
    margin-right: 30px;

    @include tablet {
      margin-right: 10px;
      flex-direction: column;
    }

    &__inner {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }

  &__delete {
    display: flex;
    &:hover {
      cursor: pointer;
    }
    &__icon {
      padding: 2px 7px 7px 7px;
      border: 1px solid #d0cece;
      border-radius: 100%;
    }
  }

  @include mobile {
    min-height: auto;
    margin-bottom: 15px;
  }

  &__image {
    display: flex;
    width: 100%;
    max-width: 60px;
    background: #f8f8f8;
    padding: 15px;
    border-radius: 12px;
    @include tablet {
      min-height: unset;
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
      object-fit: contain;
      border-radius: 12px;
      @include mobile {
        max-height: 180px;
      }
    }

    &__brand {
      left: 0;
      bottom: 0;
      padding: 0 6px;
      font-size: 12px;
      font-weight: 300;
      line-height: 21px;
      position: absolute;
      background: $white;
      color: lighten($black, 46.5%);
    }

    .WishListButton {
      position: absolute;
      right: 10px;
      top: 10px;
      margin-top: 0;
      margin-right: 0;
      padding: 5px 5px !important;
      width: 50px !important;
      min-width: 50px !important;
    }

    @include mobile {
      .WishListButton {
        position: absolute;
        bottom: 0;
        right: 5px;
        margin: 0;
        display: block;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin-left: 28px;
    @include tablet {
      margin-left: 15px;
      min-height: 73px;
    }

    &_txt {
      display: flex;
      flex-direction: column;
      justify-content: center;
      &--custom {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }

    &__title {
      margin: 0;
      color: $black;
      display: block;
      font-size: 20px;
      text-align: left;
      font-weight: 700;
      @include mobile {
        font-size: 11px;
      }
    }

    span {
      font-size: 18px;
      font-weight: 400;
      line-height: 24.3px;
      letter-spacing: -2%;
    }
  }

  &__attributes {
    display: flex;
    text-align: center;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    @include tablet {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 0;
      flex-direction: row;
      margin-top: 20px;

      span {
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
      }

      &__quantity {
        margin-right: unset !important;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      align-items: flex-start;
      > div {
        display: flex;
        flex-direction: column;
      }
      @include mobile {
        width: 100%;
        justify-content: flex-start;
      }
      li {
        margin-top: 15px;
        margin-bottom: 0;
        margin-right: 17px;
        vertical-align: top;
        display: inline-block;
        @include mobile {
          margin-top: 0;
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    &__quantity {
      display: flex;
      align-items: center;
      @include mobile {
        margin-top: 0;
        margin-right: auto;
      }
    }

    &__title {
      display: block;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      text-align: center;
      margin-bottom: 5px;
      color: darken($gray, 20.5%);
    }

    &__value {
      width: auto;
      height: auto;
      min-width: 24px;
      min-height: 24px;
      line-height: 1em;
      padding: 4px 4px 0;
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      border: 1px $black solid;
      @include mobile {
        margin-top: 4px;
      }
    }

    &__color {
      width: 24px;
      height: 24px;
      margin-top: 10px;
      background: $black;
      display: inline-block;
      vertical-align: middle;
      @include mobile {
        margin-top: 4px;
      }
    }
  }
  &__remove {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 10px;
    &:hover {
      svg {
        cursor: pointer;
      }
    }
  }
}

.loadermb {
  margin-bottom: 50px;
  @include mobile {
    width: 100%;
    height: 100%;
    margin-bottom: 15px;
  }
}

.loaderhe {
  height: 362px;
}

.mobilenone {
  @include mobile {
    display: none;
  }
}
