.OrderSingleOnHold {
  &__quantity {
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 24.3px !important;
    color: #000000 !important;
    &_title {
      font-size: 18px;
      font-weight: 400;
      line-height: 24.3px;
      color: #000;
      margin-right: 10px;
    }
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 17px;
  }
  .ProfileOrdersSingle__order-nr {
    align-self: unset !important;
    margin-bottom: 0 !important;
    width: unset !important;
  }
  .CartItem__wrapper {
    margin-right: 0 !important;
  }
}
