.MainMenu {
  // box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
  transition: 0.1s all ease-in-out;
  &__subcategories {
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    transition-delay: 300ms;
    transition-property: visibility, opacity;
    overflow: scroll !important;
    max-height: calc(100vh - 220px) !important;
    &--active {
      opacity: 1;
      visibility: visible;
      padding: 23px 0;
    }
  }

  &__active-subcategories {
    box-shadow: unset;
    transition: unset;
  }
}
