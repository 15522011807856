@import "./../../styles/lib";

.BreadCrumb {
  width: 100%;
  // height: 40px;
  display: flex;
  align-items: center;

  &__wrapper {
    padding: 20px 0;
    border-bottom: 1px solid #e8e8e8;
  }

  @include tablet {
    width: auto;
    padding: 0 30px;
    margin-left: -30px;
    margin-right: -15px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    overflow-scrolling: touch;
  }

  @include mobile {
    width: auto;
    height: 32px;
    padding: 0 15px;
    margin-left: -15px;
    margin-right: -15px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    overflow-scrolling: touch;
  }

  &__home {
    color: $black;
    font-size: 14px;
    line-height: 25px;
    margin-right: 10px;
    font-weight: normal;
    padding-right: 19px;
    background-size: 6px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: right center;
    background-image: url("./../../assets/icons/arrow-right-gray.svg");
    @include mobile {
      font-size: 12px;
    }
  }

  &__list {
    display: inline-block;
    li {
      display: inline-block;
      margin-right: 10px;
      text-transform: capitalize;

      a {
        display: block;
        color: lighten($black, 46.5%);
        font-size: 12px;
        line-height: 25px;
        font-weight: normal;
        padding-right: 19px;
        background-size: 6px;
        background-repeat: no-repeat;
        transition: 0s;
        background-position: right center;
        background-image: url("./../../assets/icons/arrow-right-gray.svg");
        @include mobile {
          font-size: 12px;
        }
      }

      &:last-child {
        a {
          background: none;
        }
      }
    }
  }
}
