@import "../../../styles/lib";

.InfoMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    margin-bottom: 20px;
  }

  &__description {
    margin: 0 auto 30px;
    p {
      font-size: 14px;
      line-height: 17px;
      color: $black;
    }
  }

  &__link {
    font-size: 18px;
    border-bottom: 1px solid $primary;
  }

  .DefaultButton {
    a {
      display: inline-block;
      width: auto;
    }
  }

  @include mobile {
    text-align: center;
  }
}
