.LoadingPlaceholder {
  padding: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  .Icon {
    margin-right: 10px;
  }
  p {
    margin: 0;
    font-size: 16px;
    color: black;
  }
}
