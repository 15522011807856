@import "./../../styles/_lib.scss";

.CartList {
  position: relative;
  display: flex;
  flex-direction: column;
  // grid-column-gap: 30px;
  // grid-template-columns: repeat(2, 4fr);
  // min-height: 150px;

  // @include tablet {
  //   grid-template-columns: repeat(2, 4fr);
  // }
  // @include small-desktop {
  //   grid-template-columns: repeat(2, 4fr);
  // }
  // @include mobile {
  //   grid-column-gap: 15px;
  //   grid-template-columns: repeat(1, 4fr);
  // }

  &__loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
}
