.Image {
  &--default {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // opacity: 0.2 !important;
    padding: 5px;

    img {
      //max-width: 70px;
      //max-height: 75px;
    }
  }
  &--contain {
    object-fit: contain !important;
  }
}
