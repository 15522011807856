@import "./../../styles/_lib.scss";

.Alert {
  border-bottom: 1px solid #fff;
  @include mobile {
    margin-top: 15px;
  }
  @include tablet {
    margin-top: 15px;
  }

  .alert {
    margin-bottom: 0;
    border-radius: unset;
    border: none;
  }

  .container {
    display: flex;
    align-items: center;
    padding-right: 0;
  }

  span.icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: $white;
    @include mobile {
      vertical-align: top;
    }
  }

  p {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: super;
    color: $black;
    width: 80%;
    line-height: em(18px, 12px);
    font-size: 12px;
  }

  button.close {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-left: auto;
    opacity: 1;
  }

  .alert-danger {
    background-color: #fcd0d0;
  }

  .alert-warning {
    background-color: #fee4b2;
  }

  .alert-success {
    background-color: #f0f6d6;
  }

  //.alert-success {
  //  span.icon {
  //    border: 1px lighten($success, 30%) solid;
  //    background-image: url("./../../assets/icons/done.svg");
  //    background-repeat: no-repeat;
  //    background-position: center center;
  //    background-size: 10px 10px;
  //  }
  //}
  //
  //.alert-danger {
  //  span.icon {
  //    border: 1px lighten($danger, 30%) solid;
  //    background-image: url("./../../assets/icons/danger.svg");
  //    background-repeat: no-repeat;
  //    background-position: center center;
  //  }
  //}
  //
  //.alert-warning {
  //  span.icon {
  //    border: 1px lighten($warning, 30%) solid;
  //    background-image: url("./../../assets/icons/info.svg");
  //    background-repeat: no-repeat;
  //    background-position: 3px center;
  //    background-size: 15px 15px;
  //  }
  //}
}
