@import "./../../styles/_lib.scss";

.HowTo {
  &__wrapper {
    margin-top: 50px;
  }
  .BreadCrumb {
    .Container {
      padding: 0 !important;
    }
  }
  &__sidebar {
    @include tablet {
      display: flex;
      justify-content: space-between;
    }
    &__filter {
      @include tablet {
        width: 100%;
        margin-top: 0;
        &:first-child {
          margin-right: 20px;
        }
      }

      margin-top: 70px;
    }
    label {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 20px;
      line-height: 20px;
      color: #000000;
      font-weight: 700;
    }
    &__categories {
      display: flex;
      flex-direction: column;
      &_category {
        padding: 10px 14px;
        display: flex;
        align-items: center;
        border-radius: 200px;
        margin-bottom: 10px;
        &:hover {
          cursor: pointer;
        }
        span {
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          color: #667085;
        }
        &_active {
          background-color: #f3f3f3;
          span {
            color: #344054;
            font-weight: 700;
          }
        }
      }
    }
  }
  &__content {
    &_big_card {
      display: flex;
      &:hover {
        cursor: pointer;
      }
      &_img {
        max-width: 660px;
        height: 345px;
        width: 100%;
        margin-right: 30px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      &_desc_label {
        display: flex;
        align-items: center;
        background: rgba(0, 74, 255, 0.02);
        padding: 6px 10px;
        color: #012db6;
        border-radius: 16px;
        font-size: 12px;
        line-height: 18px;
        width: fit-content;
        margin-bottom: 16px;

        &_video {
          background-color: white;
          border-radius: 16px;
          padding: 2px 8px;
          margin-right: 8px;
        }
      }
      &_desc {
        &_arrow {
          margin-top: 40px;
          width: fit-content;
          &:hover {
            cursor: pointer;
          }
        }
        h1 {
          font-size: 30px;
          line-height: 38px;
          color: #101828;
          margin-bottom: 12px;
          font-weight: 700;
        }
        p {
          color: #475467;
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
        }
      }
    }
    &_cards {
      display: grid;
      grid-gap: 30px;
      grid-template-columns: repeat(3, 4fr);
      margin-top: 50px;
      @include mobile {
        grid-gap: 15px;
        grid-template-columns: repeat(1, 4fr);
      }
    }
    &_small_card {
      display: flex;
      flex-direction: column;
      &:hover {
        cursor: pointer;
      }
      &_img {
        max-width: 355px;
        width: 100%;
        margin-bottom: 30px;
        height: 240px;
        @include tablet {
          margin-bottom: 10px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      &_desc_label {
        display: flex;
        align-items: center;
        background: rgba(0, 74, 255, 0.02);
        padding: 6px 10px;
        color: #012db6;
        border-radius: 16px;
        font-size: 12px;
        line-height: 18px;
        width: fit-content;
        margin-bottom: 10px;

        &_video {
          background-color: white;
          border-radius: 16px;
          padding: 2px 8px;
          margin-right: 8px;
        }
      }
      &_desc {
        &_arrow {
          width: fit-content;
          &:hover {
            cursor: pointer;
          }
        }
        h1 {
          font-size: 24px;
          line-height: 32px;
          color: #101828;
          font-weight: 700;
          margin-bottom: 0 !important;
        }
        p {
          color: #475467;
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
        }
        &_title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;
        }
      }
    }
  }
}
