.OrderFeedbackModal {
  padding: 10px;
  text-align: center;
  .form-group {
    width: 100%;
  }
  &__cart_title {
    text-align: center;
    margin-bottom: 20px !important;
  }
  &__cart_txt {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #2d2d2d;
  }
  &__grey {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    background-color: #faf9f9;
    padding: 22px;
    border-radius: 6px;

    .Select {
      max-width: unset !important;
      width: 100% !important;
    }

    span {
      color: #000000;
      font-weight: 700;
      font-size: 17px;
      line-height: 23px;
      margin-bottom: 7px;
    }
    label {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      color: #2d2d2d;
    }
    &_addr {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;
      &:nth-child(2) {
        margin-bottom: 25px;
      }
      &:nth-child(1) {
        margin-bottom: 10px;
      }
    }
  }
  .modal-content {
    border-radius: 12px !important;
  }
  &__button {
    border-radius: 200px !important;
  }
  &__button_grey {
    background-color: transparent !important;
    border: 1px solid #c2c2c2 !important;
    color: #2d2d2d;
  }
  &__close {
    display: inline;
    padding: 6px 10px;
    border-radius: 300000px;
    border: 1px solid #e8e8e8;
    margin-top: 45px;
    &:hover {
      cursor: pointer;
    }
    span {
      color: #595959;
    }
  }
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    label {
      align-self: flex-start !important;
      font-size: 17px;
      line-height: 23px;
      color: #4a5568;
    }
    .invalid-feedback {
      text-align: start !important;
      width: 100% !important;
      margin-top: 0.25rem !important;
      font-size: 80% !important;
      color: #ef3636 !important;
    }
  }
  h1 {
    font-weight: 700;
    font-size: 22px;
    line-height: 29.7px;
    margin-bottom: 40px;
  }
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  &__rate {
    display: flex;
    flex-direction: column;
    margin: 0 0 16px 0;

    label {
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
    }
    span {
      width: 100%;
      margin-top: 0.25 rem;
      font-size: 80%;
      color: #012db6;
    }
  }
  &__select {
    height: 44px !important;
  }
  textarea.form-control {
    height: 150px !important;
    border-radius: 20px !important;
  }
  .DatePicker {
    height: 44px !important;
    border: 1px solid #e8e8e8;
    border-radius: 5px !important;
    color: #4a5568 !important;
  }
}
