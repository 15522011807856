@import "../../../styles/lib";

.UNavigation {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  .Logo__mobile-menu {
    margin-left: 20px;
    &--mobile {
      margin-left: 15px;
    }
  }
  &__item {
    margin-left: 35px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &:hover {
      .HeaderCart {
        opacity: 1;
        visibility: visible;
        transform: translateX(-50%) translateY(0);
      }
      svg {
        color: $primary;
      }
    }

    &--wishlist {
      &:hover {
        svg {
          color: black !important;
        }
      }
    }

    button {
      position: relative;
      height: 80px;
      line-height: 80px;

      &:hover {
        opacity: 0.7;
      }
    }

    a {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      &:hover {
        opacity: 0.7;
      }
    }

    &--wishlist-icon {
      margin-bottom: 3px;
      position: relative;
      &-number {
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        text-align: center;
        border-radius: 50%;
        font-weight: 700;
        color: #fff;
        background-color: $primary;
        display: inline-block;
        position: absolute;
        top: -10px;
        right: -18px;
      }
    }

    &--active {
      svg {
        fill: black !important;
      }
    }

    &--wishlist-name {
      text-transform: capitalize;
    }

    &--search {
      .Icon {
        margin: 0 auto;
      }
      @include tablet {
        display: inline-flex;
      }
    }

    @include tablet {
      margin-left: 15px;

      &__label {
        display: none;
      }
    }

    //&--cart {
    //  &.onCart {
    //    a {
    //      .nr {
    //        display: block;
    //        position: absolute;
    //        color: $primary;
    //        right: -10px;
    //        font-weight: bold;
    //        font-size: 14px;
    //        z-index: 1;
    //        line-height: 0;
    //        top: 0;
    //        -webkit-animation: heartbeat 3s ease-in-out both;
    //        animation: heartbeat 3s ease-in-out both;
    //      }
    //    }
    //  }
    //}

    &--lang {
      margin-right: 0;
      button {
        border: none;
        padding-right: 0;
        background: transparent;
        &:after {
          left: 0;
          right: auto;
          width: 16px;
          height: 14px;
        }
      }
    }
  }
}
