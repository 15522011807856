@import "./../../../styles/_lib.scss";

.WishListButton {
  width: 44px;
  height: 44px;
  margin: 4px auto 0;
  text-align: center;

  .Icon {
    display: flex !important;
    position: relative;
    top: 1px;

    svg {
      stroke: darken($primary, 0);
    }
  }

  &--fill {
    svg {
      stroke: darken($primary, 0);
      fill: darken($primary, 0);
    }
  }
}
