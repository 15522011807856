@import "./../../../styles/_lib.scss";

.ShopAttributes {
  margin-bottom: 30px;
	border-bottom: 1px solid #eee;
  label {
    cursor: pointer;
  }

  small {
    font-size: 11px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 5px;
  }

  .ShopSelect__dropdown_list {
    max-height: 400px;
    overflow: auto;
  }

  .ShopSelect__dropdown_list_item_check {
    display: none !important;
  }

  &__title {
    display: block;
    font-size: 14px;
    text-align: left;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 7px;
    color: lighten($black, 72.5%);
  }

  &__color {
		width: 26px;
    height: 26px;
    display: flex;
    cursor: pointer;
    position: relative;
    border: 1px solid #e5e5e5;
    overflow: hidden;
    border-radius: 100%;
		margin: -5px -15px !important;

    > div {
			flex: 1 1;
			height: 100%;
			margin-right: 0!important;
			transform: scale(1);
    //  margin-right: 0 !important;
    //  position: absolute;
    //  left: 50%;
    //  top: 50%;
    //  transform: translate(-50%, -50%);
    }
  }
}
