@import "./../../../styles/_lib.scss";

.ShopCategories {
  margin-bottom: 30px;

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
  }

  @include tablet {
    margin-bottom: 30px;
  }

  a {
    transition: none !important;
  }

  &__select {
    select {
      width: 100%;
      border: 1px solid #d9d9d9;
      border-radius: 20px;
      height: 34px;
      color: #000000;
      outline: none;
      display: block;
      font-size: 14px;
      padding: 0 5px;
      font-weight: 600;
      // text-align: center;
    }
  }

  &__title {
    display: block;
    font-size: 14px;
    // text-transform: uppercase;
    text-align: left;
    font-weight: 900;
    line-height: 16px;
    // margin-bottom: 15px;
    color: $black;
    // border-bottom: 1px solid #eee;
    padding-bottom: 5px;
  }

  &__category {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: $black;
    display: block;
    padding: 10px 0;
    position: relative;
    cursor: pointer;

    &:hover {
      color: lighten($black, 40%);
    }

    &[data-active="true"] {
      font-weight: 700;
      color: $primary;
    }
  }
}
