.SidebarWrapper {
  &__product_type {
    margin-bottom: 30px;
		border-bottom: 1px solid #eee;
  }
  &__price {
    display: flex;
    flex-direction: column;
  }
}
