.ColumnMenuLevel {
  display: none;
  padding: 23px 0;
  background: #fff;
  &--active {
    display: block;
  }
  &__wrapper {
    width: 100%;
    display: flex;
  }
  &__list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
    height: fit-content;
    text-transform: capitalize;
    &--icon {
      width: 21px;
      margin-right: 12px;
      margin-bottom: 0;
      text-align: center;
      &:hover {
        cursor: pointer;
      }
      > img {
        width: 21px;
        height: 21px;
        object-fit: contain;
      }
    }
    li {
      a {
        display: flex;
        align-items: center;
        color: #000000;
        background: no-repeat 0 center;
      }
    }
  }

  &__btn_all {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 10px;
    // height: 100%;

    &__text {
      &:hover {
        background: rgba(0, 0, 0, 0.6);
      }
      span {
        color: black;
        font-size: 12px;
        letter-spacing: 0.15em;
        text-transform: uppercase;
      }
    }
  }
  &-menu-listing-child {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  &__all {
    a {
      font-size: 16px !important;
      line-height: 21.6px !important;
      font-weight: 400 !important;
      color: #9a9a9a !important;
      text-decoration: underline !important;
    }
  }
}


.ColumnMenuLevel--parent{
  width: 100%;
  font-weight: 600;
  float:right;
  text-align: right;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 2px solid black;
}