.LoaderOverlay {
  top: 0;
  left: 0;
  z-index: 102;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);

  display: flex;
  flex-direction: column;
}
