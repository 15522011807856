.SingleProduct__variations_wrapper {
  .ShopSelect__dropdown {
    position: unset;
  }
  .ShopSelect__dropdown_list {
    grid-template-columns: repeat(6, 6fr);
  }
}
.spvw_new {
  margin-top: 40px;
}
