.SingleProduct__variations_wrapper {
  &_stock {
    font-size: 16px;
    line-height: 21.6px;
    letter-spacing: -2%;
    font-weight: 400;
    margin-top: 15px;
  }
  .ShopSelect__dropdown {
    position: unset;
  }
  .ShopSelect__dropdown_list {
    grid-template-columns: repeat(6, 6fr);
  }
}
