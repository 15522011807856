@import "../../../../styles/_lib.scss";
$item-spacing: 40px;
$active-color: #000 !important;
$default-color: #b9b9b9 !important;

.InlineMenuLevel {
  padding-top: 20px;
  background: #fff;
  display: none;

  &--active {
    display: block;
    &-border {
      border-bottom: 1px solid #dddddd;
      transition-delay: 290ms;
    }
  }
  &__menu {
    display: flex;
    flex-wrap: wrap;
    text-transform: capitalize;
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: black;
      border: 3px solid transparent;
      border-radius: 9px;
      background-clip: content-box;
    }

    &__item {
      margin-right: $item-spacing;
      @include small-desktop {
        margin-right: 20px;
      }
      text-transform: capitalize;
      &--link {
        a {
          color: #f9a51a !important;
          font-weight: bold;
          font-size: 16px;
          text-transform: capitalize;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      a {
        font-size: 16px;
        position: relative;
        margin-bottom: 20px;
        color: rgb(0, 0, 0);
        display: inline-block;
        outline: none !important;
        text-transform: capitalize;
        transition: all 0.3s ease-in-out;
        white-space: nowrap;
        text-transform: capitalize;
        &:after {
          left: 0;
          z-index: 5;
          opacity: 0;
          width: 100%;
          content: "";
          height: 2px;
          bottom: -1px;
          position: absolute;
          background: $default-color;
          transition: all 0.3s ease-in-out;
        }
        &:hover {
          color: $active-color;
          &:after {
            opacity: 1;
            background: $active-color;
          }
        }
      }
      &--active {
        a {
          color: $active-color;
          &:after {
            opacity: 1;
            background: $active-color;
          }
        }
      }
    }
  }
}
