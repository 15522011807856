.ScrollToTop {
  position: relative;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    position: fixed;
    bottom: 130px;
    right: 35px;
    z-index: 20;
    background-color: #fff;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    color: #000;
    border: 1px solid #d2d2d2;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
  }
}
