@import "./../../../styles/_lib.scss";

.DesktopMenu {
  opacity: 1;
  z-index: 3;
  width: 100%;
  position: absolute;
  visibility: visible;
  transition: all 0.2s ease-in-out;
  &--hidden {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
}

@include mobile {
  .DesktopMenu {
    opacity: 0 !important;
    visibility: hidden !important;
  }
}

@include tablet {
  .DesktopMenu {
    opacity: 0 !important;
    visibility: hidden !important;
  }
}
