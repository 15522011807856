.Register {
  .Chip {
    border-radius: 20px;
    padding: 10px;
    &:hover {
      cursor: pointer;
    }
  }

  .filled {
    background-color: #012db6;
    color: white;
    border: 1px solid #012db6;
  }

  .outlined {
    color: #4a5568;
    background-color: white;
    border: 1px solid #012db6;
  }
  position: relative;
  .Login__wrapper {
    max-width: 675px;
  }
  h4 {
    font-weight: 600;
    margin-bottom: 30px;
  }

  .RegisterFormStepThree {
    .Select {
      max-width: unset;
    }
    &__chips {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(2, 2fr);
    }
  }

  .RegisterFormStepTwo {
    .Login__wrapper {
      max-width: 422px !important;
    }
  }
  .RegisterForm__left {
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 18px;
    margin-bottom: 30px;

    span {
      font-size: 17px;
      font-weight: 400;
      line-height: 23px;
    }
  }
  &__steps {
    display: flex;
    justify-content: center;
    align-items: center;
    &_wrapper {
      width: 100%;
      max-width: 675px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 23px;
      span {
        font-size: 17px;
        line-height: 23px;
        font-weight: 500;
      }
      &_dots {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .Login__goBack {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    span {
      font-size: 16px;
      line-height: 21.6px;
      font-weight: 500;
      color: #475569;
    }
  }
}
