@import "src/styles/lib";

.NotificationsHeader {
  position: relative;
  &:hover {
    cursor: pointer;
  }
  &__dropdown {
    position: absolute;
    right: 40px;
    top: 40px;
    background: white;
    border-radius: 6px;
    padding: 20px;
    z-index: 101;
    width: 300px;
    height: 320px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    @include tablet {
      right: -80px;
    }
    h1 {
      font-size: 20px;
      line-height: 27px;
      font-weight: 700;
      color: #000000;
      margin-bottom: 10px;
    }
    &_items {
      overflow: scroll;
      display: flex;
      flex-direction: column;
      height: 87%;
      overflow: scroll;
      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }

      /* Hide scrollbar for IE, Edge and Firefox */
    }
    &_item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid #e8e8e8;
      padding: 17px 0;
      &:hover {
        cursor: pointer;
      }
      &:last-child {
        border: none;
        padding-bottom: 0;
      }

      &_img {
        border: 1px solid #e7e7e7;
        padding: 7px;
        border-radius: 12px;
        background-color: white;
        margin-right: 10px;
      }
      &_desc {
        span {
          font-size: 16px;
          font-weight: 700;
          line-height: 21.6px;
        }
      }
    }
  }
}

.Overlay {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}
