@import "./../../styles/_lib.scss";

.DefaultPage {
  margin-top: 55px;

  @include mobile {
    margin-top: 20px;
  }

  @include tablet {
    margin-top: 50px;
  }

  &__heading {
    margin-bottom: 35px;

    &__excerpt {
      font-weight: 700;
      font-size: 14px;
      line-height: 1.75em;
      color: $black;
      display: block;

      p {
        margin-bottom: 0;
      }
    }
  }

  &__image {
    width: 100%;
    height: auto;
    position: relative;
    min-height: 242px;
    @include mobile {
      min-height: unset;
      margin-left: -15px;
      margin-right: -15px;
      width: auto;
    }
    @include tablet {
      min-height: unset;
      margin-left: -30px;
      margin-right: -30px;
      width: auto;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &__thumb {
    width: 100%;
    height: 450px;
    margin-bottom: 20px;
    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
    }
  }

  &__content {
    position: relative;
    @include mobile {
      margin-top: unset;
    }
    @include tablet {
      margin-top: unset;
    }

    .container {
      background: $white;
    }

    .ThemeContainer {
      padding-top: 47px;
    }

    p {
      font-size: 14px;
      line-height: 1.75em;
      color: $black;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 20px 0;
      font-weight: normal;
      line-height: 25px;
      color: $black;
      display: inline-block;
    }

    img {
      max-width: 100%;
      width: auto;
      height: auto;
    }

    ul {
      list-style-type: disc !important;
      padding-left: 17px;
      margin-bottom: 30px !important;
    }

    ol {
      font-weight: 700;
      padding-left: 17px;
      margin-bottom: 30px;

      li {
        font-weight: normal;
      }
    }

    blockquote {
      margin-bottom: 30px;
      background: lighten($gray, 3.4%);
      padding: 15px 30px 0 52px;
      display: inline-block;
      max-width: 630px;
      position: relative;

      &:before {
        content: '"';
        position: absolute;
        top: -5px;
        left: 15px;
        font-size: 80px;
        color: $black;
      }
    }
  }
}
